<template>
  <div id="occasion-view">
    <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link :to="path">
              <h3><i class="fa fa-arrow-left"></i> Back</h3>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <section class="bg-light">
      <main></main>
      <div class="col-md-12">
        <div class="findgft"></div>
      </div>
      <div class="container-fluid pt-5"></div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <ul>
              <li
                v-for="occasion in occasion_list"
                class="sm_megamenu_title ml-4 my-3"
                @click="showGift(occasion)"
              >
                <h3>
                  <span class="col-md-3">
                    <img
                      src="@/assets/site/images/calendar.svg"
                      width="30"
                      height="30"
                      class="img-fluid"
                      alt=""
                    />
                  </span>
                  <span class="col-md-8">
                    {{ occasion.name ? occasion.name : occasion.occasion_name }}
                  </span>
                </h3>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <gift-view
              v-if="show"
              v-bind:giftproducts="giftproducts"
            ></gift-view>
          </div>
        </div>
      </div>

      <div class="container">
        <hr />
      </div>
    </section>

    <!-- End Footer -->
  </div>
</template>
<script>
import GiftView from "@/views/site/user/GiftView";

export default {
  name: "Occasiongift",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Explore a variety of occasions to find the perfect gifts! Navigate through different categories and view tailored gift suggestions for any special event.",
      },
      {
        name: "keywords",
        content:
          "occasion gifts, gift suggestions, special events, gift categories, navigate occasions, gift ideas, online shopping, user-friendly interface, wishlist management, gift viewing, occasion lists, festive gifting, personalized gifts, event-based gifts, gift selection",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      giftproducts: [],
      occasion_list: [],
      imageshow: false,
      show: false,
      page_name: null,
      path: null,
    };
  },
  components: { GiftView },
  created() {
    this.getGiftsContacts();
    this.page_name = this.$route.params.page_name;
    this.path = this.$route.params.path;
  },
  methods: {
    getGiftsContacts() {
      this.occasion_list = this.$route.params.occasion_list;
    },
    showGift(occasion) {
      this.show = true;
      this.giftproducts = occasion.products;
    },
  },
};
</script>
